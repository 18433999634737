import React from "react";
import { NavLink } from "react-router-dom";

interface PropTypes {
  content: string;
  url: string;
  active: string;
  setActiveStatus: React.Dispatch<React.SetStateAction<string>>;
}

export default function HeaderButton({ content, url, active, setActiveStatus }: PropTypes) {
  return (
    <NavLink
      to={url}
      className={`mx-1 lg:mx-2 xl:mx-4 px-2 xl:px-3 py-2 rounded-lg hover:text-header-button-hoverTextColor hover:bg-header-button-hover text-white uppercase font-bold cursor-pointer ${
        active.includes(url) ? "bg-header-button-hover text-header-button-hoverTextColor" : ""
      }`}
      onClick={() => {
        // navigate();
        setActiveStatus(url);
      }}
    >
      {content}
    </NavLink>
  );
}
