import React from "react";
import HeaderButton from "./HeaderButton";
import WalletConnection from "./WalletConnection";

interface PropTypes {
  show: boolean;
  active: string;
  setActiveStatus: React.Dispatch<React.SetStateAction<string>>;
}

export default function NavMenu(props: PropTypes): React.ReactElement {
  return (
    <div
      className={`invisible p-5 text-white flex flex-col fixed w-full z-50 h-screen backdrop-blur-md transition duration-500 ${
        props.show ? "opacity-100 !visible" : "opacity-0"
      }`}
      style={{ transitionProperty: "all" }}
    >
      <HeaderButton content="collections" url="/collections" active={props.active} setActiveStatus={props.setActiveStatus}></HeaderButton>
      <HeaderButton
        url="/admin-tools/distribute-airdrop"
        content="Distribute Airdrop(ERC-20)"
        active={props.active}
        setActiveStatus={props.setActiveStatus}
      ></HeaderButton>
      <HeaderButton
        url="/admin-tools/distribute-nft"
        content="Distribute Airdrop(NFT)"
        active={props.active}
        setActiveStatus={props.setActiveStatus}
      ></HeaderButton>
      <HeaderButton
        url="/admin-tools/query-transaction"
        content="QUERY TRANSACTIONS"
        active={props.active}
        setActiveStatus={props.setActiveStatus}
      ></HeaderButton>
      {/* <HeaderButton content='games' url='/games'></HeaderButton> */}
      {/* <div className='p-5'>
                <WalletConnection></WalletConnection>
            </div> */}
    </div>
  );
}
