import styled, { keyframes } from "styled-components";

interface IProgressbar {
  status: number;
}

const loadingAnimation = keyframes`
    from {
        background-position-x: -10px;
    }
    to {
        background-position-x: 0px;
    }
`;
const ProgressBar = styled.div<IProgressbar>`
  width: ${(props) => props.status}%;
  height: 40px;
  background-color: #09042e;
`;
const Container = styled.div`
  height: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;
  justify-content: flex-end;
  background-color: rgb(90, 70, 226);
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgb(123, 107, 232) 4px, rgb(123, 107, 232) 8px);
  animation: ${loadingAnimation} 1s linear infinite;
  will-change: background-position-x;
`;

export default function LoadingBar({ currentState = 30 }) {
  return (
    <Container>
      <ProgressBar status={100 - currentState}></ProgressBar>
    </Container>
  );
}
