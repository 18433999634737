import React, { useEffect } from "react";
import { Col, Row, DatePickerProps, Form, Button } from "antd";
import { messageGroupRequestId, messageInvalidWalletAddress, regexToken } from "app/utils/constant";
import { Dayjs } from "dayjs";
import { validate } from "uuid";
import LoadingDistribute from "../DistributesAirdrop/ExportData/LoadingDistribute";
import { TransactionPagingDetails } from "myria-core-sdk/dist/types";

interface Props {
  setGroupRequestID: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  data: any | undefined;
  setPaginationTransaction: React.Dispatch<React.SetStateAction<TransactionPagingDetails | undefined>>;
  paginationTransaction: TransactionPagingDetails | undefined;
  defaultGroupRequestID: string;
}

const FormQueryTransaction: React.FC<Props> = ({
  setGroupRequestID,
  isLoading,
  data,
  setPaginationTransaction,
  paginationTransaction,
  defaultGroupRequestID = "",
}) => {
  const [minValueDate, setMinValueDate] = React.useState<Dayjs | null>(null);
  const [maxValueDate, setMaxValueDate] = React.useState<Dayjs | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);
  const [totalPageData, setTotalPageData] = React.useState<number>(data?.items.length || 0);
  const [maxTotalPage, setMaxTotalPage] = React.useState<number>(0);

  useEffect(() => {
    if (paginationTransaction === undefined && data === undefined) return;

    if (maxTotalPage === 0 && data && data.items) {
      setTotalPageData(totalPageData + data.items.length);
    }
    if (data && !data.lastEvaluatedKey) {
      setMaxTotalPage(totalPageData);
    } else {
      if (data && paginationTransaction !== undefined) {
        if (maxTotalPage === 0 && data.lastEvaluatedKey) {
          setTotalPageData(totalPageData + data.items.length);
        }
      }
      if (data && paginationTransaction === undefined) {
        if (maxTotalPage === 0 && data.lastEvaluatedKey) {
          setTotalPageData(data.items.length);
        }
      }
    }
  }, [data, paginationTransaction]);
  const handleChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    setMaxValueDate(date);
  };
  const handleChangeDateMin: DatePickerProps["onChange"] = (date, dateString) => {
    setMinValueDate(date);
  };
  const handleOnFinish = (value: any) => {
    if (value.groupRequestId) {
      setGroupRequestID(value.groupRequestId);
    }
  };

  return (
    <Form name="normal_login" onFinish={handleOnFinish} className="text-white">
      <Row gutter={16} className="mt-8" justify="space-between">
        <Col className="flex items-center flex-col justify-start min-w-[408px]" span={12}>
          {/* <div className="flex items-center mb-3 w-full">
            <h2 className="mx-4 min-w-[152px]">Receiver Wallet Address</h2>
            <Form.Item
              className="mb-0 min-w-[200px] w-full"
              name="receiverWalletAddress"
              rules={[
                {
                  required: false,
                },
                ({ getFieldValue }) => ({
                  validator: (rule: any, value: string) => {
                    if (value && !Web3.utils.isAddress(value)) {
                      return Promise.reject(messageInvalidWalletAddress);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <div className="bg-white min-w-[200px] w-full rounded-md border-[2px] border-transparent hover:border-[rgba(59,130,246,0.5)]">
                <input className="w-full rounded-md px-3 py-0 h-8 transition-all text-[rgba(0,0,0,0.88)] bg-transparent border-none outline-none" />
              </div>
            </Form.Item>
          </div> */}
          <div className="flex items-center mb-3 w-full">
            <h2 className="mx-4 min-w-[152px]">Group Request ID</h2>
            <Form.Item
              className="mb-0 min-w-[200px] w-full"
              name="groupRequestId"
              rules={[
                {
                  required: false,
                },
                ({ getFieldValue }) => ({
                  validator: (rule: any, value: string) => {
                    if (value && !validate(value)) {
                      return Promise.reject(messageGroupRequestId);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <div className="bg-white min-w-[200px] w-full rounded-md border-[2px] border-transparent hover:border-[rgba(59,130,246,0.5)]">
                <input
                  className="w-full rounded-md px-3 py-0 h-8 transition-all text-[rgba(0,0,0,0.88)] bg-transparent border-none outline-none"
                  defaultValue={defaultGroupRequestID}
                  onMouseDown={() => setErrorMessage(false)}
                />
              </div>
            </Form.Item>
          </div>
          {/* <div className="flex items-center mb-3 w-full">
            <h2 className="mx-4 min-w-[152px]">Date</h2>
            <Space direction="horizontal" className="w-full">
              <Form.Item className="mb-0" name="minDate">
                <DatePicker
                  className="h-9"
                  onChange={handleChangeDateMin}
                  value={minValueDate}
                  disabledDate={(d) => {
                    return maxValueDate ? d > maxValueDate : false;
                  }}
                  placeholder="Start Date"
                />
              </Form.Item>
              to
              <Form.Item className="mb-0" name="maxDate">
                <DatePicker
                  className="h-9"
                  onChange={handleChangeDate}
                  value={maxValueDate}
                  disabledDate={(d) => {
                    return minValueDate ? d < minValueDate : false;
                  }}
                  placeholder="End Date"
                />
              </Form.Item>
            </Space>
          </div> */}
        </Col>
        <Col className="flex items-end">
          <Form.Item className="mb-0 flex flex-row">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button flex items-center bg-[#1677ff] w-32 h-10 justify-center text-lg font-semibold"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {errorMessage ? <div className="text-red-600">There are no available transactions for this request ID</div> : null}
      {isLoading && !data ? <LoadingDistribute /> : null}
      <Row className="justify-end mt-6 gap-4">
        {data ? (
          <>
            <button
              className="flex items-center rounded hover:opacity-80 bg-[#d4b240] disabled:opacity-50 disabled:bg-transparent w-32 h-10 justify-center text-lg font-semibold"
              onClick={() => setPaginationTransaction(undefined)}
              disabled={paginationTransaction === undefined}
            >
              Prev
            </button>
            <button
              className="flex items-center rounded hover:opacity-80 bg-[#d4b240] disabled:opacity-50 disabled:bg-transparent w-32 h-10 justify-center text-lg font-semibold"
              onClick={() => {
                setPaginationTransaction({ ...data.lastEvaluatedKey, limit: 1000 });
              }}
              disabled={data.lastEvaluatedKey === undefined}
            >
              Next
            </button>
            {data ? <div className="text-white w-full text-right">Total Transactions: {totalPageData} </div> : null}
          </>
        ) : null}
      </Row>
    </Form>
  );
};

export default FormQueryTransaction;
