import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Buttons';

export default function Alert() {
    const navigate = useHistory();
    return (
        <div className='w-full h-full flex flex-col items-center justify-center'>
            <img src='/assets/images/warning.png' alt="warning!" className='w-28 h-28'></img>
            <div className='flex flex-col h-36 justify-between items-center p-6 border border-solid border-header-button-hover rounded-lg'>
                <div className='text-2xl'>
                    You are not logged in yet. Please login, first.
                </div>
                <Button content={'Go to Login'} className="px-5 !m-0" onClick={() => navigate.push('/login')}></Button>
            </div>

        </div>
    )
}