import React from 'react';

interface PropTypes {
    onClick: () => void;
}

export default function MenuButton (props: PropTypes) {
    const [active, setActive] = React.useState<boolean>(false);
    const handleClick = () => {
        setActive(!active);
        props.onClick();
    }
    return (
        <div className='w-6 h-menuButton flex flex-col items-center justify-around cursor-pointer' onClick={() => handleClick()}>
            <div className={`w-6 h-default flex justify-center bg-white transition-transform ${active ? 'origin-right -rotate-45' : 'origin-right rotate-0'}`}></div>
            <div className={`w-6 h-default flex justify-center bg-white transition-transform ${active ? 'origin-right scale-0' : 'origin-right scale-100'}`}></div>
            <div className={`w-6 h-default flex justify-center bg-white transition-transform ${active ? 'origin-right rotate-45' : 'origin-right rotate-0'}`}></div>
        </div>
    )
}