import RetryDistributedTokenComponent from "app/components/DistributesAirdrop/RetryDistributes";
import LayoutAuth from "app/layout/LayoutAuth";

const RetryDistribute = () => {
  return (
    <LayoutAuth>
      <RetryDistributedTokenComponent />
    </LayoutAuth>
  );
};

export default RetryDistribute;
