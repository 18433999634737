import LayoutAuth from "app/layout/LayoutAuth";
import DistributesNFTComp from "../../components/DistributesNFT";

const DistributesNFT = () => {
  return (
    <LayoutAuth>
      <DistributesNFTComp isEditable={false} />
    </LayoutAuth>
  );
};

export default DistributesNFT;
