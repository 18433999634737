import Alert from "app/components/Alert";
import { checkAuthStatus } from "app/utils/pipeline";
import axios, { CancelToken } from "axios";
import React, { useEffect, useState } from "react";
import { BallTriangle } from "react-loader-spinner";

interface Props {
  children: React.ReactNode;
}

const LayoutAuth: React.FC<Props> = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const getCollectionList = async (cancelToken: CancelToken) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setLoading(true);
      try {
        const authStatus = await checkAuthStatus(accessToken, cancelToken);
        if (authStatus) {
          setLoginStatus(true);
          setLoading(false);
        } else {
          setLoginStatus(false);
        }
      } catch {
        setLoading(false);
        setLoginStatus(false);
      }
    }
  };
  useEffect(() => {
    let source = axios.CancelToken.source();
    getCollectionList(source.token);
    return () => {
      source.cancel();
    };
  }, []);
  return loginStatus ? (
    <div className="max-w-7xl m-auto min-h-[calc(100vh-96px)] py-6">
      <div className="bg-header-background min-h-[calc(100vh-96px-48px)] px-4 rounded-lg">
        {loading ? (
          <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
            <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  ) : (
    <Alert></Alert>
  );
};

export default LayoutAuth;
