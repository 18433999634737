import LayoutAuth from "app/layout/LayoutAuth";
import DistributesAirdropComp from "../../components/DistributesAirdrop";

const DistributesAirdrop = () => {
  return (
    <LayoutAuth>
      <DistributesAirdropComp isEditable={false} />
    </LayoutAuth>
  );
};

export default DistributesAirdrop;
