import { numberOfData } from "app/components/DistributesAirdrop";
import XLSX from "xlsx";

export function ExcelRenderer(file: any, callback: any, isCSVtext: boolean) {
  if (isCSVtext) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      var rABS = !!reader.readAsBinaryString;
      reader.onload = function (e: any) {
        const csvOutput = e.target.result;
        const parseData = csvOutput.split(/[\r\n]+/);
        const groupRequestId = parseData[0].split(":")[1];
        const csvHeader = parseData[1].split(/[,;]+/);
        const csvRow = handle_row(parseData);
        const data = { rows: csvRow, cols: csvHeader, groupRequestId: numberOfData > 1 ? groupRequestId.trim() : undefined };
        resolve(data);
        return callback(null, data);
      };
      if (file && rABS) reader.readAsText(file);
      else reader.readAsBinaryString(file);
    });
  } else {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      var rABS = !!reader.readAsBinaryString;
      reader.onload = function (e: any) {
        /* Parse data */
        var bstr = e.target.result;
        var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

        /* Get first worksheet */
        var wsname = wb.SheetNames[0];
        var ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        var json = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
        const rowHeader = json[0];
        const groupRequestId = rowHeader?.[0].split(":")[1];

        var cols = make_cols(ws["!ref"]);

        var data = { rows: json.splice(numberOfData), cols: cols, groupRequestId: numberOfData > 1 ? groupRequestId.trim() : undefined };
        resolve(data);
        return callback(null, data);
      };
      if (file && rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  }
}

function make_cols(refstr: any) {
  var o: any = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return o;
}
function handle_row(rows: any) {
  let row: any = [];
  for (let i = numberOfData; i < rows.length; i++) {
    if (rows[i].length === 0 && i === rows.length - 1) continue;
    row[i - numberOfData] = rows[i].split(/[,;]/);
  }
  return row;
}
