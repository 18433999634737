import React, { useState } from "react";
import { editWalletAddress } from "app/utils/pipeline";
import { useAuthenticationContext } from "context/authentication";
import DisconnectModal from "../Modal/DisconnectModal";

export default function WalletConnection() {
  const [modalDisconnect, setModalDisconnect] = useState<boolean>(false);
  const { connectL2Wallet, walletAddress, starkKey, isConnected } = useAuthenticationContext();
  const handleClick = async () => {
    if (walletAddress.length <= 2 || starkKey.length <= 2 || !isConnected) {
      await connectL2Wallet();
    } else {
      setModalDisconnect(!modalDisconnect);
    }
  };

  return (
    <div className="flex items-center mr-5">
      <div
        className="flex items-center px-2 py-3 min-w-[152px] rounded-lg cursor-pointer bg-backgroundColor1 border-default border-header-button-border1"
        onClick={() => handleClick()}
      >
        <svg className="mr-3" width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.66602 6.5H4.66602C4.39268 6.5 4.16602 6.27333 4.16602 6C4.16602 5.72667 4.39268 5.5 4.66602 5.5H8.66602C8.93935 5.5 9.16602 5.72667 9.16602 6C9.16602 6.27333 8.93935 6.5 8.66602 6.5Z"
            fill="white"
          ></path>
          <path
            d="M12.6926 9.86613C11.6859 9.86613 10.8326 9.11946 10.7526 8.15946C10.6993 7.60613 10.8993 7.06614 11.2993 6.67281C11.6326 6.32614 12.1059 6.13281 12.6059 6.13281H13.9993C14.6593 6.15281 15.166 6.67278 15.166 7.31279V8.68616C15.166 9.32616 14.6593 9.84613 14.0193 9.86613H12.6926ZM13.9793 7.13281H12.6126C12.3793 7.13281 12.166 7.21948 12.0126 7.37948C11.8193 7.56614 11.726 7.81946 11.7526 8.07279C11.786 8.51279 12.2126 8.86613 12.6926 8.86613H13.9993C14.086 8.86613 14.166 8.78616 14.166 8.68616V7.31279C14.166 7.21279 14.0859 7.13948 13.9793 7.13281Z"
            fill="white"
          ></path>
          <path
            d="M10.6673 14.1654H4.66732C2.37398 14.1654 0.833984 12.6254 0.833984 10.332V5.66536C0.833984 3.61203 2.10063 2.12537 4.0673 1.8787C4.2473 1.85204 4.45398 1.83203 4.66732 1.83203H10.6673C10.8273 1.83203 11.034 1.8387 11.2473 1.87203C13.214 2.0987 14.5007 3.59203 14.5007 5.66536V6.63204C14.5007 6.90537 14.274 7.13204 14.0007 7.13204H12.614C12.3806 7.13204 12.1673 7.2187 12.014 7.3787L12.0073 7.38538C11.8207 7.56538 11.734 7.81202 11.754 8.06535C11.7873 8.50535 12.214 8.85868 12.694 8.85868H14.0007C14.274 8.85868 14.5007 9.08535 14.5007 9.35868V10.3254C14.5007 12.6254 12.9607 14.1654 10.6673 14.1654ZM4.66732 2.83203C4.50732 2.83203 4.35398 2.84536 4.20064 2.86536C2.73398 3.05202 1.83398 4.1187 1.83398 5.66536V10.332C1.83398 12.052 2.94732 13.1654 4.66732 13.1654H10.6673C12.3873 13.1654 13.5007 12.052 13.5007 10.332V9.86536H12.694C11.6873 9.86536 10.834 9.11869 10.754 8.15869C10.7007 7.61202 10.9007 7.06538 11.3007 6.67871C11.6473 6.32538 12.114 6.13204 12.614 6.13204H13.5007V5.66536C13.5007 4.10536 12.5873 3.03202 11.1073 2.85868C10.9473 2.83202 10.8073 2.83203 10.6673 2.83203H4.66732Z"
            fill="white"
          ></path>
        </svg>
        <div className="text-white font-medium text-sm">
          {walletAddress.length > 2 && starkKey.length > 2 ? editWalletAddress(walletAddress) : "Connect Wallet"}
        </div>
      </div>
      {modalDisconnect ? <DisconnectModal setModalDisconnect={setModalDisconnect} /> : null}
    </div>
  );
}
