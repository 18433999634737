import React, { useEffect, useMemo, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./style.css";
import { useHistory } from "react-router-dom";

interface Props {
  numberCurrentItems: number;
  numberMaxItems: number;
}

const ProgressBarDistribute: React.FC<Props> = ({ numberCurrentItems, numberMaxItems }) => {
  const percentageBulkTransfer = (numberCurrentItems * 100) / numberMaxItems;
  return (
    <div className="mt-8 flex items-center">
      <div className="w-80 progress-container h-8">
        <ProgressBar className="w-full h-full flex" now={percentageBulkTransfer} label={`${numberCurrentItems + "/" + numberMaxItems} `}></ProgressBar>
      </div>
    </div>
  );
};

export default ProgressBarDistribute;
