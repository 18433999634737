import Web3 from "web3";
import { provider } from "web3-core";
import {
  EnvTypes,
  CollectionManager,
  GetCollectionParams,
  CommonPaginateDataTypes,
  CollectionListResponse,
  CollectionDetailsResponseData,
  UpdateCollectionByContractAddressParams,
} from "myria-core-sdk";
import { AdminUpdateCollectionInfo } from "types";
import axios, { AxiosInstance } from "axios";

const API_DEV_HOST = "https://dev.myriacore-marketp-api.nonprod-myria.com/v1";
const API_STAGING_HOST = "https://staging.myriacore-marketp-api.nonprod-myria.com/v1";
const API_PROD_HOST = "https://myriacore-marketp-api.myria.com/v1";

const DEV_API_KEY = "9cf85c179540cd7b6479de659a5df4f25abb14e62bbd5d8949334bc6197d9b53";
const STAGING_API_KEY = "8b40f27fed47b99fedc8ed627482e77502cf332425a7618712f93e609e56fade";
const PROD_API_KEY = "5ba65dddafd129c65a4804007330d6d7133209f6253843d41edeea459b580896";

const host = {
  [EnvTypes.DEV]: API_DEV_HOST,
  [EnvTypes.STAGING]: API_STAGING_HOST,
  [EnvTypes.PRODUCTION]: API_PROD_HOST,
};

const apiKeys = {
  [EnvTypes.DEV]: DEV_API_KEY,
  [EnvTypes.STAGING]: STAGING_API_KEY,
  [EnvTypes.PRODUCTION]: PROD_API_KEY,
};

const createInstance = (env: EnvTypes): AxiosInstance => {
  const baseUrl = host[env];
  const apiKey = apiKeys[env];

  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      "x-api-admin-key": apiKey,
    },
  });
};
export class Modules {
  private walletProvider?: any;
  private web3Instance?: any;
  private collectionManager: CollectionManager;
  private axiosInstance: AxiosInstance;

  constructor(env: EnvTypes, provider?: provider, web3Instance?: Web3) {
    this.collectionManager = new CollectionManager(env);
    this.walletProvider = provider;
    this.web3Instance = web3Instance;
    this.axiosInstance = createInstance(env);
  }

  public async connectWallet(): Promise<string | undefined> {
    try {
      if (this.walletProvider) {
        const accounts: string[] = await (window as any)?.ethereum.request({ method: "eth_requestAccounts" });
        return accounts[0];
      }
    } catch (err) {
      console.log(err);
    }
  }

  public async getCollections(params?: GetCollectionParams): Promise<CommonPaginateDataTypes<CollectionListResponse[]> | undefined> {
    let collectionList: CommonPaginateDataTypes<CollectionListResponse[]> | undefined;
    try {
      const response = await this.collectionManager.getCollectionList(params);

      if (response?.status === "success") {
        collectionList = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return collectionList;
  }

  public async getCollectionById(id: number): Promise<CollectionDetailsResponseData | undefined> {
    let collection: CollectionDetailsResponseData | undefined;

    try {
      const response = await this.collectionManager.getCollectionById(id);

      if (response) {
        collection = response;
      } else {
        collection = undefined;
      }
    } catch (err) {
      console.log(err);
    }

    return collection;
  }

  public async updateCollectionByContractAddress(params: UpdateCollectionByContractAddressParams): Promise<CollectionDetailsResponseData | undefined> {
    let collection: CollectionDetailsResponseData | undefined;

    try {
      const response = await this.collectionManager.updateCollectionByContractAddress(params);

      if (response) {
        collection = response;
      } else {
        collection = undefined;
      }
    } catch (err) {
      console.log(err);
    }

    return collection;
  }

  public async updateCollectionMetadataApiUrl(contractAddress: string, params: AdminUpdateCollectionInfo) {
    const result = await this.axiosInstance({
      method: "PATCH",
      url: `/admin/collections/${contractAddress}`,
      data: params,
    });

    return result;
  }
}
