import React, { Dispatch, SetStateAction } from "react";
import Web3 from "web3";
import { provider } from "web3-core";

export interface Web3ContextType {
  provider?: provider;
  web3Instance?: Web3;
  setProvider: Dispatch<SetStateAction<provider | undefined>>;
  setWeb3Instance: Dispatch<SetStateAction<Web3 | undefined>>;
  currentAccount?: string;
  setCurrentAccount: Dispatch<SetStateAction<string | undefined>>;
}

export const Web3Context = React.createContext({} as Web3ContextType);

export default function Web3ContextProvider({ children }) {
  const [provider, setProvider] = React.useState<provider>();
  const [web3Instance, setWeb3Instance] = React.useState<Web3>();
  const [currentAccount, setCurrentAccount] = React.useState<string>();

  React.useEffect(() => {
    const givenProvider = Web3.givenProvider;
    if (givenProvider) {
      setProvider(givenProvider);
      const web3 = new Web3(givenProvider);
      setWeb3Instance(web3);
    }
  }, []);

  return (
    <Web3Context.Provider
      value={{
        provider,
        setProvider,
        web3Instance,
        setWeb3Instance,
        currentAccount,
        setCurrentAccount,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
