import axios from "axios";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export default function PrivateRoute(props: RouteProps) {
  // const isLoggedin = localStorage.getItem("session");
  // const { login }: AuthContextInterface = React.useContext(AuthContext);

  const login = localStorage.getItem("accessToken");
 
  return login ? <Route {...props} /> : <Redirect to={'/login'}></Redirect>
}
