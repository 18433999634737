import React from 'react';
import { Button } from '../Buttons';

interface PropTypes {
    warningMessage?: string;
    onClose?: () => void;
    showModal: boolean;
}

export default function WarningModal({ warningMessage, onClose, showModal }: PropTypes) {
    return (
        showModal ? <div className='fixed top-0 left-0 w-screen h-screen flex items-center bg-[#000000cc] justify-center z-20'>
            <div className='flex flex-col items-center justify-center border border-solid py-10 border-header-button-hover bg-header-button-hover rounded-lg'>
                <img src='/assets/images/warning.png' alt="warning!" className='w-28 h-28'></img>
                <div className='flex flex-col h-36 justify-between items-center p-6 '>
                    <div className='text-2xl'>
                        {
                            warningMessage
                        }
                    </div>
                    <Button content={'OK'} className="px-5 !m-0" onClick={() => onClose ? onClose() : {}}></Button>
                </div>
            </div>
        </div>
        : <div></div>
    )
}