import { CopyOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { arrayFieldCopy, dataIndexDefault, handlerCopy, statusBulk, titleExcel } from "app/utils/constant";
import { EditableCell, EditableFormRow, ItemTable } from "app/utils/editable";
import { editWalletAddress } from "app/utils/pipeline";
import React from "react";
import { handleStatusRender } from "../DistributesAirdrop/RetryDistributes";

interface Props {
  rows: any[];
  column: ColumnsType<any> | undefined;
  pagination: false | TablePaginationConfig | undefined;
}

const TableComp: React.FC<Props> = ({ rows, column, pagination }) => {
  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };
  return <Table components={components} rowClassName={() => "editable-row"} dataSource={rows} columns={column} pagination={pagination} />;
};

const handleRenderColumn = (text: string, record: any, dataIndex: dataIndexDefault) => {
  return (
    <div className="editable-cell-value-wrap flex items-center">
      <span>{dataIndex === dataIndexDefault.smartContract || (text && text.length > 42) ? editWalletAddress(text) : text}</span>
      {arrayFieldCopy.includes(dataIndex) ? <CopyOutlined className="p-2 hover:cursor-pointer" onClick={() => handlerCopy(text)} /> : null}
    </div>
  );
};
const handlerClickRetry = () => {
  console.log("retry");
};

const renderRetryDistributeBulk = (status: statusBulk) => {
  if (status) {
    return handleStatusRender[status]?.functionRender(handlerClickRetry);
  }
};

const columnsMyria = [
  // {
  //   title: titleExcel.key,
  //   dataIndex: dataIndexDefault.key,
  //   editable: false,
  // },
  {
    title: titleExcel.sender,
    dataIndex: dataIndexDefault.sender,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.sender),
  },
  {
    title: titleExcel.receiverWalletAddress,
    dataIndex: dataIndexDefault.receiverWalletAddress,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.receiverWalletAddress),
  },
  {
    title: titleExcel.tokenType,
    dataIndex: dataIndexDefault.tokenType,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.token),
  },
  {
    title: titleExcel.token,
    dataIndex: dataIndexDefault.token,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.token),
  },
  {
    title: titleExcel.status,
    dataIndex: dataIndexDefault.status,
    editable: false,
    render: (text: string, record: any) => renderRetryDistributeBulk(record.status),
  },
];

const columnsNFT = [
  // {
  //   title: titleExcel.key,
  //   dataIndex: dataIndexDefault.key,
  //   editable: false,
  // },
  {
    title: titleExcel.sender,
    dataIndex: dataIndexDefault.sender,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.sender),
  },
  {
    title: titleExcel.receiverWalletAddress,
    dataIndex: dataIndexDefault.receiverWalletAddress,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.receiverWalletAddress),
  },
  {
    title: titleExcel.tokenType,
    dataIndex: dataIndexDefault.tokenType,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenType),
  },
  {
    title: titleExcel.smartContract,
    dataIndex: dataIndexDefault.smartContract,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.smartContract),
  },
  {
    title: titleExcel.tokenId,
    dataIndex: dataIndexDefault.tokenId,
    editable: false,
    render: (text: string, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenId),
  },
  {
    title: titleExcel.status,
    dataIndex: dataIndexDefault.status,
    editable: false,
    render: (text: string, record: any) => renderRetryDistributeBulk(record.status),
  },
];

export const columnMyria = columnsMyria.map((col, index) => {
  if (!col.editable) {
    return col;
  }
  return {
    ...col,
    onCell: (record: ItemTable) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave: undefined,
    }),
  };
});
export const columnNFT = columnsNFT.map((col, index) => {
  if (!col.editable) {
    return col;
  }
  return {
    ...col,
    onCell: (record: ItemTable) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave: undefined,
    }),
  };
});

export default TableComp;
