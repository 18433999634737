import React from "react";
import { Button } from "../Buttons";

export default function Modal({ show, onClose, collectionValue, collectionInfo, setUpdateCollection }) {
  const changeInfo = (e: any) => {
    const temp = collectionInfo;
    temp[e.target.name] = e.target.value;
    // console.log(temp);
    setUpdateCollection(temp);
  };

  const handleOk = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  // To make updateInfo value to be empty!
  React.useEffect(() => {
    // if (show) {
    //   setUpdateInfo({});
    // }
  }, [show]);

  return (
    show && (
      <div className="fixed z-50 top-0 w-full h-screen backdrop-blur-sm backdrop-brightness-75 flex items-center justify-center">
        <div className="flex flex-col justify-between p-8 w-96 h-[500px] rounded-3xl bg-backgroundColor1">
          <div className="flex w-full justify-between items-center text-yellow-600">
            Name:&nbsp;&nbsp;&nbsp;
            <input
              name="name"
              defaultValue={collectionValue?.name ? collectionValue.name : ""}
              className="text-black w-full rounded-md outline-none p-1"
              onChange={(e) => changeInfo(e)}
            ></input>
          </div>
          <div>
            <div className="text-yellow-600">Description:&nbsp;&nbsp;&nbsp;</div>
            <textarea
              name="description"
              defaultValue={collectionValue?.description ? collectionValue.description : ""}
              className="w-full p-2 text-black rounded-md outline-none"
              rows={5}
              onChange={(e) => changeInfo(e)}
            ></textarea>
          </div>
          <div>
            <div className="text-yellow-600">Metadata Api URL:&nbsp;&nbsp;&nbsp;</div>
            {/* <input
              name="metadataApiUrl"
              defaultValue={initialValue?.metadataApiUrl ? initialValue.metadataApiUrl : ""}
              className="w-full text-black rounded-md outline-none p-1"
              onChange={(e) => changeInfo(e)}
            ></input> */}
            <div className="text-white">{collectionValue?.metadataApiUrl ? collectionValue.metadataApiUrl : ""}</div>
          </div>
          <div>
            <div className="text-yellow-600">Is Hot?</div>
            <div className="w-full flex justify-between">
              <div className="flex items-center mt-2">
                <label htmlFor="hot" className="text-sm font-medium cursor-pointer">
                  Hot:&nbsp;&nbsp;
                </label>
                <input
                  id="hot"
                  type="radio"
                  defaultChecked={String(collectionValue.isHot) === "true" ? true : false}
                  value={"true"}
                  name="isHot"
                  className="w-4 h-4 cursor-pointer"
                  onChange={(e) => changeInfo(e)}
                ></input>
              </div>
              <div className="flex items-center cursor-pointer">
                <label htmlFor="notHot" className="text-sm font-medium cursor-pointer">
                  Not Hot:&nbsp;&nbsp;
                </label>
                <input
                  id="notHot"
                  name="isHot"
                  defaultChecked={String(collectionValue.isHot) === "false" ? true : false}
                  className="w-4 h-4 cursor-pointer"
                  type={"radio"}
                  value={"false"}
                  onChange={(e) => changeInfo(e)}
                ></input>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button content={"Ok"} className={"px-4 mr-4"} onClick={() => handleOk()}></Button>
            <Button content={"Cancel"} className={"px-4 !mx-0"} onClick={() => handleCancel()}></Button>
          </div>
        </div>
      </div>
    )
  );
}
