import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Form, Input } from "antd";
import type { FormInstance, RuleObject } from "antd/es/form";
import {
  messageInvalidToken,
  messageInvalidSender,
  regexToken,
  messageInvalidWalletAddress,
  requireField,
  dataIndexDefault,
  messageInvalidSmartContract,
} from "./constant";
import Web3 from "web3";
import { StoreValue } from "antd/lib/form/interface";
import { toast } from "react-toastify";
import { TokenType } from "myria-core-sdk";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export interface ItemTable {
  [dataIndexDefault.key]: any;
  [dataIndexDefault.sender]: any;
  [dataIndexDefault.receiverWalletAddress]: any;
  [dataIndexDefault.token]?: any;
  [dataIndexDefault.smartContract]?: any;
  [dataIndexDefault.tokenId]?: any;
  [dataIndexDefault.tokenType]?: TokenType;
}

interface EditableRowProps {
  index: number;
}

export const EditableFormRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ItemTable;
  record: ItemTable;
  handleSave?: (record: ItemTable) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log("save", values);
      toggleEdit();
      toast("Save Success", {
        type: "success",
      });
      handleSave && handleSave({ ...record, ...values });
    } catch (errInfo) {
      toast("Save Failed!", {
        type: "error",
      });
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    switch (dataIndex) {
      case dataIndexDefault.sender:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    validator(rule: RuleObject, value: StoreValue) {
                      return new Promise((resolve, reject) => {
                        if (!Web3.utils.isAddress(value)) {
                          reject(messageInvalidSender);
                        }
                      });
                    },
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!Web3.utils.isAddress(childNode?.[1])) {
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidSender : requireField}
                </div>
              </div>
            );
          } else {
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      case dataIndexDefault.receiverWalletAddress:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    validator(rule: RuleObject, value: StoreValue) {
                      return new Promise((resolve, reject) => {
                        if (!Web3.utils.isAddress(value)) {
                          reject(messageInvalidWalletAddress);
                        }
                      });
                    },
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!Web3.utils.isAddress(childNode?.[1])) {
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidWalletAddress : requireField}
                </div>
              </div>
            );
          } else {
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      case dataIndexDefault.token:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regexToken),
                    message: messageInvalidToken,
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!regexToken.test(childNode?.[1])) {
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidToken : requireField}
                </div>
              </div>
            );
          } else {
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      case dataIndexDefault.tokenId:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regexToken),
                    message: messageInvalidToken,
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!regexToken.test(childNode?.[1])) {
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidToken : requireField}
                </div>
              </div>
            );
          } else {
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      case dataIndexDefault.tokenType:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    validator(rule: RuleObject, value: StoreValue) {
                      return new Promise((resolve, reject) => {
                        if (!Web3.utils.isAddress(value)) {
                          reject(messageInvalidSmartContract);
                        }
                      });
                    },
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!Web3.utils.isAddress(childNode?.[1])) {
            console.log(Web3.utils.isAddress(childNode?.[1]));
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidSmartContract : requireField}
                </div>
              </div>
            );
          } else {
            console.log(Web3.utils.isAddress(childNode?.[1]));
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      case dataIndexDefault.smartContract:
        {
          if (editing) {
            childNode = (
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    validator(rule: RuleObject, value: StoreValue) {
                      return new Promise((resolve, reject) => {
                        if (!Web3.utils.isAddress(value)) {
                          reject(messageInvalidSmartContract);
                        }
                      });
                    },
                  },
                ]}
              >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
              </Form.Item>
            );
          } else if (!Web3.utils.isAddress(childNode?.[1])) {
            console.log(Web3.utils.isAddress(childNode?.[1]));
            childNode = (
              <div className="flex w-full flex-col">
                <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]" onClick={toggleEdit}>
                  {children}
                </div>
                <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
                  {childNode?.[1] ? messageInvalidSmartContract : requireField}
                </div>
              </div>
            );
          } else {
            console.log(Web3.utils.isAddress(childNode?.[1]));
            childNode = (
              <div
                className="editable-cell-value-wrap"
                style={{
                  paddingRight: 24,
                }}
                onClick={toggleEdit}
              >
                {children}
              </div>
            );
          }
        }
        break;
      default:
        return null;
    }
  }
  return <td {...restProps}>{childNode}</td>;
};
