import { getTransaction, queryKey } from "app/utils/constant";
import { TransactionPagingDetails } from "myria-core-sdk";
import { useQuery } from "react-query";

export const useQueryTransaction = (groupRequestID: string, transactionPaging?: TransactionPagingDetails, loop?: boolean | false, refetchInterval?: number) => {
  return useQuery(
    [queryKey.getTransactionAPI, groupRequestID, transactionPaging],
    async () => {
      const getData = await getTransaction(groupRequestID, transactionPaging);
      return getData;
    },
    {
      enabled: !!groupRequestID,
      staleTime: loop ? undefined : Infinity, // Call only the first init component
      refetchInterval: refetchInterval,
    }
  );
};
