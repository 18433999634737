import axios, { CancelToken } from "axios";
import { listEmailAdmin } from "./constant";

const editWalletAddress = (address: string): string => {
  if (address && address.length > 5) {
    const prefix = address.slice(0, 4);
    const suffix = address.slice(-4);
    return prefix + "..." + suffix;
  } else {
    return address;
  }
};

const checkAuthStatus = async (accessToken: string, cancelToken?: CancelToken): Promise<boolean> => {
  const result = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancelToken,
  });
  if ((result.data.hd && result.data.hd === "myria.com") || listEmailAdmin.includes(result.data.email)) {
    return true;
  }
  return false;
};

export { editWalletAddress, checkAuthStatus };
