export const codeInfoAccount = {
    userExited: "USER_REGISTERED",
    userNotRegister: "USER_NOT_REGISTERED",
    userGetError: "GET_USER_INFO_ERROR"
}

export const USER_MESSAGE_REGISTER = {
    successRegister: "User has registered successfully, you can verify it now",
    errorMessageRegister: "Internal server error, can’t perform an active at the moment. Please try again later",
    userExistedMessage: "This user has been existed and registered successfully already",
    networkError: "Network Error!",
    disconnectAfterRegister: "Please Disconnect before Register!",
    noDetectedMetamask: "",
    connectWalletBeforeGenerate: "Please connect your MetaMask wallet first!",
    generateStarkKeyAlready: "Stark Key generated already!"
}

export const changeNetwork = async (provider: any, ENV_CHAIN_ID: number) => {
    const chainId = ENV_CHAIN_ID;
    const changeIdHex = "0x" + chainId.toString(16);
    try {
      await provider?.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: changeIdHex,
          },
        ],
      });
    } catch (err: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (err.code === 4902) {
        await provider?.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: changeIdHex,
            },
          ],
        });
      } else {
        throw err;
      }
    }
  };