import { useQueryTransaction } from "app/hooks/useQueryTransaction";
import { TokenType, TransactionPagingDetails } from "myria-core-sdk";
import React from "react";
import { useParams } from "react-router-dom";
import TableComp, { columnMyria, columnNFT } from "../TablesComp";
import FormQueryTransaction from "./FormQueryTransaction";
import { QUERY_TRANSACTIONS_INTERVAL } from "app/utils/constant";

interface IUserPublicProfileRouteParams {
  paramGroupRequestID: string;
}

const QueryTransaction: React.FC = () => {
  let { paramGroupRequestID } = useParams<IUserPublicProfileRouteParams>();
  const [groupRequestID, setGroupRequestID] = React.useState<string>(paramGroupRequestID || "");
  const [paginationTransaction, setPaginationTransaction] = React.useState<TransactionPagingDetails | undefined>(undefined);
  const dataTransaction = useQueryTransaction(groupRequestID, paginationTransaction, false, QUERY_TRANSACTIONS_INTERVAL);

  return (
    <>
      <div className="flex justify-center items-center text-2xl font-semibold pt-8">
        <img className="w-6 h-6" src="/assets/images/santa-hat.png" />
        <h1 className="mx-4">Query Transaction Dashboard</h1>
        <img className="w-6 h-6" src="/assets/images/santa-hat.png" />
      </div>
      <FormQueryTransaction
        setGroupRequestID={setGroupRequestID}
        isLoading={dataTransaction.isLoading || dataTransaction.isFetching}
        data={dataTransaction.data}
        setPaginationTransaction={setPaginationTransaction}
        paginationTransaction={paginationTransaction}
        defaultGroupRequestID={groupRequestID}
      />
      {dataTransaction.data && dataTransaction.data?.items.length > 0 ? (
        <div className="mt-4 min-w-[1212px]">
          {dataTransaction &&
          (dataTransaction.data.items[0].tokenType === TokenType.ERC20 || dataTransaction.data.items[0].tokenType === TokenType.MINTABLE_ERC20) ? (
            <TableComp
              rows={dataTransaction.data.items ?? []}
              column={columnMyria}
              pagination={{
                position: ["bottomRight", "topRight"],
                // onChange: onChangePage,
                defaultPageSize: 100,
              }}
            />
          ) : (
            <TableComp
              rows={dataTransaction.data.items ?? []}
              column={columnNFT}
              pagination={{
                position: ["bottomRight", "topRight"],
                // onChange: onChangePage,
                defaultPageSize: 100,
              }}
            />
          )}
        </div>
      ) : (
        <div className="mt-4 min-w-[1212px]">
          <TableComp
            rows={[]}
            column={columnNFT}
            pagination={{
              position: ["bottomRight", "topRight"],
              // onChange: onChangePage,
              defaultPageSize: 100,
            }}
          />
        </div>
      )}
    </>
  );
};

export default QueryTransaction;
