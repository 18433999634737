import React from "react";
import { ThreeDots } from "react-loader-spinner";

export default function Button({ content, ...props }) {
  return (
    <button
      {...props}
      className={
        "w-fit h-9 flex items-center justify-center my-4 active:bg-yellow-500 hover:bg-yellow-300 bg-yellow-600 text-header-background font-bold rounded-md mr-5 " +
        props?.className
      }
    >
      {props.loading && String(props.loading) === "true" ? (
        <ThreeDots height="30" width="30" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" visible={true} />
      ) : (
        content
      )}
    </button>
  );
}
