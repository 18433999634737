import React, { Dispatch, SetStateAction } from 'react';

interface PropTypes {
    pageList: any[];
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function PaginationButton({ pageList, currentPage, setCurrentPage }: PropTypes) {
    const getPageList = () => {
        if (pageList.length < 4) {
            return pageList.map((page, index) => index + 1).slice(1);
        } else if (currentPage <= 4) {
            return [1, 2, 3, 4, 5];
        } else if (currentPage > pageList.length - 4) {
            return Array(5).fill(0).map((ele, index) => index).reverse().map((value) => pageList.length - value);
        } else {
            return [currentPage - 1, currentPage, currentPage + 1];
        }
    }

    const handleSetPage = (pageNum: number) => {
        setCurrentPage(pageNum);
    }

    return (
        <React.Fragment>
            <button
                className={'bg-yellow-500 disabled:bg-yellow-700 w-24 p-3 my-4 rounded-r-full cursor-pointer'}
                onClick={() => handleSetPage(1)}
            >
                First
            </button>
            {
                currentPage > 4 && <React.Fragment>
                    <button className={`rounded-full h-10 w-10 m-2 ${currentPage === 1 ? 'bg-transparent border-[2px] border-yellow-500' : 'bg-yellow-500'}`} onClick={() => handleSetPage(1)}>{1}</button>
                    <span>...</span>
                </React.Fragment>
            }
            {
                getPageList().map(num => {
                    return (
                        <button className={`rounded-full h-10 w-10 m-2 ${currentPage === num ? 'bg-transparent border-[2px] border-yellow-500' : 'bg-yellow-500'}`} key={num} onClick={() => handleSetPage(num)}>{num}</button>
                    )
                })
            }
            {
                currentPage <= (pageList.length - 4) && <React.Fragment>
                    <span>...</span>
                    <button className={`rounded-full h-10 w-10 m-2 ${currentPage === pageList.length ? 'bg-transparent border-[2px] border-yellow-500' : 'bg-yellow-500'}`} onClick={() => handleSetPage(pageList.length)}>{pageList.length}</button>
                </React.Fragment>
            }
            <button
                className={'bg-yellow-500 disabled:bg-yellow-700 w-24 p-3 my-4 rounded-l-full cursor-pointer outline-none'}
                onClick={() => handleSetPage(pageList.length)}
            >
                Last
            </button>
        </React.Fragment>
    )
}