import { dataIndexDefault, regexToken } from "app/utils/constant";
import { ItemTable } from "app/utils/editable";
import React, { useState } from "react";
import Web3 from "web3";
import { CSVLink } from "react-csv";
import { v4 as uuidV4 } from "uuid";

interface Props {
  filename: any;
  cols: any[];
  rows?: ItemTable[];
  template: boolean;
}

const templateData = {
  row: [
    {
      [dataIndexDefault.key]: "1",
      [dataIndexDefault.sender]: "0xe584CCc59E4570c0C9Ed374815c86f9FB8fe9220",
      [dataIndexDefault.receiverWalletAddress]: "0xDcd4367D4E0Dc19315431407cC1b41c5b9DD18B2",
      [dataIndexDefault.smartContract]: "0xbE4464d3F61615c193a79DFd58C6adB473f480F2",
      [dataIndexDefault.tokenId]: "1",
    },
  ],
};

const CSVRequestsExport: React.FC<Props> = ({ filename, cols, rows, template = false }) => {
  const [groupRequestId, setGroupRequestId] = useState<string>(`groupRequestId: ${uuidV4()}`);
  let validatedForm = true;
  const columnHandle = cols.map((col, index) => {
    return col.title;
  });

  const columns = columnHandle.filter((col, index) => {
    return col !== "Index";
  });

  const checkData = rows ? rows : templateData.row;

  const rowData = checkData.map((row: ItemTable, index) => {
    if (!Web3.utils.isAddress(row.sender) || !Web3.utils.isAddress(row.receiverWalletAddress) || !regexToken.test(row.token)) {
      validatedForm = false;
    }
    return [row.sender, row.receiverWalletAddress, row.smartContract, row.tokenId];
  });
  const dataCSV = [[groupRequestId], columns, ...rowData];
  const csvContent = dataCSV.map((e) => e.join(",")).join("\n");

  return (
    <div className="excel-export-container">
      {validatedForm || template ? (
        <CSVLink
          filename={filename}
          className="download-excel-button hover:opacity-80 hover:text-white"
          target="_blank"
          data={csvContent}
          onClick={() => {
            setGroupRequestId(`groupRequestId: ${uuidV4()}`);
          }}
        >
          <span> {template ? "Download Template CSV" : "Download"}</span>
          <img src="/assets/images/csvIcon.png" alt="csvIcon" />
        </CSVLink>
      ) : (
        <button disabled className="download-excel-button">
          <span> {template ? "Download Template" : "Download"}</span>
          <img src="/assets/images/csvIcon.png" alt="" />
        </button>
      )}
    </div>
  );
};

export default CSVRequestsExport;
