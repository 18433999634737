import { dataIndexDefault, regexToken } from "app/utils/constant";
import { ItemTable } from "app/utils/editable";
import React, { useState } from "react";
import Workbook from "react-excel-workbook";
import { v4 as uuidV4 } from "uuid";
import Web3 from "web3";

interface Props {
  filename: any;
  cols: any[];
  rows?: ItemTable[];
  template: boolean;
  disableDownload?: boolean;
}

const templateData = {
  row: [
    {
      [dataIndexDefault.key]: "1",
      [dataIndexDefault.sender]: "0xe584CCc59E4570c0C9Ed374815c86f9FB8fe9220",
      [dataIndexDefault.receiverWalletAddress]: "0xDcd4367D4E0Dc19315431407cC1b41c5b9DD18B2",
      [dataIndexDefault.token]: "123456789",
    },
  ],
};

const ExcelExampleExport: React.FC<Props> = ({ filename, cols, rows, template = false, disableDownload }) => {
  const [groupRequestId, setGroupRequestId] = useState<string>(`groupRequestId: ${uuidV4()}`);
  let validatedForm = true;

  const columnHandle = cols.filter((col, index) => {
    return col.dataIndex !== "key" && col.dataIndex !== "action";
  });

  const columns = columnHandle.map((col, index) => {
    return {
      label: index == 0 ? groupRequestId : "",
      value: col.dataIndex,
    };
  });

  const columnsLabel = {
    sender: columnHandle[0].title,
    receiverWalletAddress: columnHandle[1].title,
    token: columnHandle[2].title,
  };
  const checkData = rows ? rows : templateData.row;

  const rowData = checkData.map((row: ItemTable, index) => {
    if (!Web3.utils.isAddress(row.sender) || !Web3.utils.isAddress(row.receiverWalletAddress) || !regexToken.test(row.token)) {
      validatedForm = false;
    }
    return {
      sender: row.sender,
      receiverWalletAddress: row.receiverWalletAddress,
      token: row.token,
    };
  });

  const worksheets = [
    {
      name: "Wallet",
      columns: columns,
      data: [columnsLabel, ...rowData],
    },
  ];

  return (
    <div className="excel-export-container">
      {validatedForm || template ? (
        <Workbook
          filename={filename}
          element={
            <button
              className={`download-excel-button hover:opacity-80 ${disableDownload ? "pointer-events-none" : ""}`}
              onClick={() => setGroupRequestId(`groupRequestId: ${uuidV4()}`)}
            >
              <span> {template ? "Download Template Excel" : "Download"}</span>
              <img src="/assets/images/excelIcon.png" alt="" />
            </button>
          }
        >
          {worksheets.map(({ name, columns, data }, index) => {
            return (
              <Workbook.Sheet key={index} name={name} data={data}>
                {columns.map(({ label, value }, idx) => {
                  return <Workbook.Column key={idx} label={label} value={value} />;
                })}
              </Workbook.Sheet>
            );
          })}
        </Workbook>
      ) : (
        <button disabled className="download-excel-button">
          <span> {template ? "Download Template" : "Download"}</span>
          <img src="/assets/images/excelIcon.png" alt="" />
        </button>
      )}
    </div>
  );
};

export default ExcelExampleExport;
