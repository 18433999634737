import React from "react";
import LayoutAuth from "app/layout/LayoutAuth";
import { QueryTransaction } from "app/components/QueryTransactions";

const QueryTransactionPage = () => {
  return (
    <LayoutAuth>
      <QueryTransaction />
    </LayoutAuth>
  );
};

export default QueryTransactionPage;
