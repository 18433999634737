import Header from "app/components/Header";
import styled from "styled-components";

export default function Layout({ children }) {
  return (
    <Container>
      <div className="flex-grow-0">
        <Header></Header>
      </div>
      <div className="flex-grow h-screen overflow-auto text-white bg-backgroundColor1">
        <BodyContainer>{children}</BodyContainer>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
`;
