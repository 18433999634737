import React from "react";
import AWS from "aws-sdk";
import LoadingBar from "../LoadingBar";
import { accessKeyId, bucketName, region, secretAccessKey } from "config";
import clsx from "clsx";

export default function UploadBanner({ imgUrl, icon, setUploadFileUrl, setWarning, setWarningMessage }) {
  const [selectedFile, setSelectedFile] = React.useState<any>();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [uploadFailed, setUploadFailed] = React.useState(false);

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });

  const myBucket = new AWS.S3({
    params: {
      Bucket: bucketName,
    },
    region: region,
  });

  const getFile = (e: any) => {
    setWarningMessage("Please upload only 2880 * 672 images.");
    setUploadProgress(0);

    const file = e.target?.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      var image = new Image();

      image.src = window.URL.createObjectURL(file);

      image.onload = (e) => {
        console.log("image size is ", image.height, image.width);

        if (image.height !== 672 || image.width !== 2880) {
          setWarning(true);
        } else {
          setWarning(false);
          setSelectedFile(fileReader.result);
          setLoading(true);
          const uploadParams = {
            Bucket: bucketName,
            Body: file,
            Key: file.name,
            ContentType: file.type,
          };

          myBucket
            .putObject(uploadParams)
            .on("httpUploadProgress", (evt) => {
              setUploadProgress(Math.round((evt.loaded / evt.total) * 100));
              if ((evt.loaded / evt.total) * 100 == 100) {
                setLoading(false);
                const fileName = encodeURIComponent(file.name);
                setUploadFileUrl(`https://${bucketName}.s3.amazonaws.com/${fileName}`);
              }
            })
            .send((err) => {
              if (err) {
                console.log("uploading error!", err);
                setLoading(false);
                setUploadFailed(true);
              }
            });
        }
      };
    };
  };

  return (
    <div className={clsx("w-full h-full relative inline-flex bg-header-button-hover group overflow-hidden", icon && "rounded-full")}>
      <img
        style={{ objectFit: "cover", position: "absolute", width: "100%", height: "327px" }}
        src={
          selectedFile
            ? !uploadFailed
              ? selectedFile
              : imgUrl
              ? imgUrl
              : "/assets/images/placeholder.jpg"
            : imgUrl
            ? imgUrl
            : "/assets/images/placeholder.jpg"
        }
      />
      {/* <img src={} className="h-full absolute w-full object-cover object-top"></img> */}
      {!loading ? (
        <div
          className={clsx(
            "w-full h-full overflow-hidden flex items-center justify-center mx-auto  space-y-3 opacity-0 hover:opacity-100 transition-all duration-300 hover:backdrop-brightness-50 backdrop-blur-none hover:backdrop-blur-sm",
            icon && "rounded-full"
          )}
        >
          <div>
            {!icon ? (
              <React.Fragment>
                <div className="text-white md:text-[60px] text-[30px] flex items-center justify-center w-full h-full">Click to Upload</div>
                <div className="text-white md:text-[40px] text-[22px] flex items-center justify-center w-full h-full">Banner images should be 2880 * 672</div>
              </React.Fragment>
            ) : (
              <div className="flex items-center justify-center w-full h-full backdrop-brightness-50 backdrop-blur-sm">
                <img src="/assets/images/edit-button.png" className="w-10 h-10"></img>
              </div>
            )}
          </div>
          <input
            id="collectionImageUpload"
            type="file"
            className={clsx("opacity-0 bottom-0 cursor-pointer absolute w-full h-full", icon && "rounded-full")}
            onChange={(e) => getFile(e)}
          />
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center backdrop-blur backdrop-brightness-50">
          <div className={`text-white mb-1 ${icon ? "text-sm font-semibold" : "text-3xl font-bold"}`}>Uploading...</div>
          <div className="w-4/6">
            <LoadingBar currentState={uploadProgress}></LoadingBar>
          </div>
        </div>
      )}
    </div>
  );
}
