import React, { useEffect, useMemo, useState } from "react";
import { Button, Row, Col, Upload, PaginationProps } from "antd";
import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import { ItemTable } from "../../utils/editable";
import ExcelRequestsExport from "./ExportData/ExcelRequestsExport";
import { toast } from "react-toastify";
import Web3 from "web3";
import {
  arrayFieldCopy,
  asyncCallWithTimeout,
  dataIndexDefault,
  handlerCopy,
  maxTransactionData,
  messageErrorGroupRequestId,
  messageErrorMaxTransaction,
  messageErrorSenderInvalidAccount,
  messageInvalidSender,
  messageInvalidSmartContract,
  messageInvalidTokenId,
  messageInvalidWalletAddress,
  messageUserNoSignature,
  nftDescription,
  parseErrorAxios,
  partnerRefId,
  regexToken,
  requireField,
  senderReceiverInvalid,
  timeoutBulkTransfer,
  titleExcel,
} from "app/utils/constant";
import { ExcelRenderer } from "app/utils/rendererData";
import CSVRequestsExport from "./ExportData/CSVRequestsExport";
import LoadingDistribute from "../DistributesAirdrop/ExportData/LoadingDistribute";
import TableComp from "../TablesComp";
import { editWalletAddress } from "app/utils/pipeline";
import { validate } from "uuid";
import { Link, useHistory } from "react-router-dom";
import { useAuthenticationContext } from "context/authentication";
import { ItemSignableTransferParams, TokenType, TransferTokenParams } from "myria-core-sdk";
import { getModuleFactory } from "app/service/myriaCodeSDK";

interface Props {
  isEditable: boolean;
}

interface currentPage {
  page: number | undefined;
  pageSize: number | undefined;
}

export const numberOfData = 2;

const DistributesNFTComp: React.FC<Props> = ({ isEditable }) => {
  const rowsExcelLocal = localStorage.getItem("rowExcel");
  const nameOfExcel = localStorage.getItem("nameOfExcel");
  const groupRequestIdLocal = localStorage.getItem("groupRequestId");
  if (!nameOfExcel || !rowsExcelLocal || !groupRequestIdLocal) {
    localStorage.removeItem("rowExcel");
    localStorage.removeItem("nameOfExcel");
    localStorage.removeItem("groupRequestId");
  }
  const rowsInitExcel = rowsExcelLocal ? JSON.parse(rowsExcelLocal) : [];
  const [rows, setRows] = useState<any[]>(rowsInitExcel);
  // const [cols, setCols] = useState<any[]>([]);
  const [groupRequestId, setGroupRequestId] = useState<string | undefined>(groupRequestIdLocal?.replaceAll('"', "") || undefined);

  const [currentPage, setCurrentPage] = useState<currentPage>({
    page: undefined,
    pageSize: undefined,
  });
  const { walletAddress } = useAuthenticationContext();
  const navigate = useHistory();
  const [activeLaunch, setActiveLaunch] = useState<boolean>(false);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);

  const conditionActiveLaunchExcel = useMemo(() => {
    let checkWalletAddressSender = true;
    rows.map((row, index) => {
      if (row.sender && row.sender.toLowerCase() !== walletAddress.toLowerCase()) {
        checkWalletAddressSender = false;
      }
    });
    return checkWalletAddressSender;
  }, [rows, walletAddress]);

  useEffect(() => {
    if (rows) {
      if (document.getElementsByClassName("ant-form-item-explain-error").length === 0 && groupRequestId) {
        setActiveLaunch(true);
      } else {
        setActiveLaunch(false);
      }
    }
  }, [rows]);

  useEffect(() => {
    if (!rows?.[0]?.tokenId) {
      localStorage.removeItem("rowExcel");
      localStorage.removeItem("nameOfExcel");
      localStorage.removeItem("groupRequestId");
      setRows([]);
    }
  }, []);

  //Handle change page in pagination to set current page
  const onChangePage: PaginationProps["onChange"] = (page: number, pageSize: number) => {
    setCurrentPage({ ...currentPage, page, pageSize });
  };

  const handleDataIndex = {
    [dataIndexDefault.sender]: {
      functionRender: (text: any) => {
        return !Web3.utils.isAddress(text);
      },
      message: messageInvalidSender,
    },
    [dataIndexDefault.receiverWalletAddress]: {
      functionRender: (text: any) => {
        return !Web3.utils.isAddress(text);
      },
      message: messageInvalidWalletAddress,
    },
    [dataIndexDefault.smartContract]: {
      functionRender: (text: any) => {
        return !Web3.utils.isAddress(text);
      },
      message: messageInvalidSmartContract,
    },
    [dataIndexDefault.tokenId]: {
      functionRender: (text: any) => {
        return !regexToken.test(text);
      },
      message: messageInvalidTokenId,
    },
  };

  //Handle Render Column
  const handleRenderColumn = (text: any, record: any, dataIndex: dataIndexDefault) => {
    if (
      handleDataIndex[dataIndex].functionRender(text) ||
      (record.receiverWalletAddress === record.sender && (dataIndex === dataIndexDefault.receiverWalletAddress || dataIndex === dataIndexDefault.sender))
    ) {
      return (
        <div className="flex w-full flex-col">
          <div className="editable-cell-value-wrap h-8 flex w-full items-center px-3 rounded-[4px] border-[1px] border-[#ff0000]">
            {dataIndex === dataIndexDefault.smartContract ? editWalletAddress(text) : text}
          </div>
          <div className="notification-message-error ant-form-item-explain-error text-[#ff4d4f] flex">
            {text
              ? record.receiverWalletAddress === record.sender &&
                (dataIndex === dataIndexDefault.receiverWalletAddress || dataIndex === dataIndexDefault.sender)
                ? senderReceiverInvalid
                : handleDataIndex[dataIndex].message
              : requireField}
          </div>
        </div>
      );
    } else {
      return (
        <div className="editable-cell-value-wrap flex items-center">
          <span>{dataIndex === dataIndexDefault.smartContract ? editWalletAddress(text) : text}</span>
          {arrayFieldCopy.includes(dataIndex) ? <CopyOutlined className="p-2 hover:cursor-pointer" onClick={() => handlerCopy(text)} /> : null}
        </div>
      );
    }
  };

  //template and field column render UI
  const columns = [
    {
      title: titleExcel.key,
      dataIndex: dataIndexDefault.key,
      editable: false,
    },
    {
      title: titleExcel.sender,
      dataIndex: dataIndexDefault.sender,
      editable: isEditable,
      render: isEditable ? undefined : (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.sender),
    },
    {
      title: titleExcel.receiverWalletAddress,
      dataIndex: dataIndexDefault.receiverWalletAddress,
      editable: isEditable,
      render: isEditable ? undefined : (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.receiverWalletAddress),
    },
    {
      title: titleExcel.smartContract,
      dataIndex: dataIndexDefault.smartContract,
      editable: isEditable,
      render: isEditable ? undefined : (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.smartContract),
    },
    {
      title: titleExcel.tokenId,
      dataIndex: dataIndexDefault.tokenId,
      editable: isEditable,
      render: isEditable ? undefined : (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenId),
    },
  ];

  //Handle save data when change input data or add data
  const handleSave = (row: ItemTable) => {
    // setErrorMessage(null);
    const newData = [...rows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setRows(newData);
  };

  //Function handle size file
  const checkFile = (file) => {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel = file[0].type === "application/vnd.ms-excel" || file[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
    }
    console.log("file", file[0].type);
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 2MB!";
    }
    // console.log("errorMessage", errorMessage);
    return errorMessage;
  };

  //Handle file before render by lib renderer excel
  const fileHandler = (fileList: any) => {
    const callBack = (err: any, resp: any) => {
      if (err) {
        toast("Error Render File!", {
          type: "error",
        });
        console.log(err);
      } else {
        let newRows: ItemTable[] = [];
        resp.rows.map((row: any, index: number) => {
          if (row && row !== "undefined") {
            const data: ItemTable = {
              [dataIndexDefault.key]: index,
              [dataIndexDefault.sender]: row[0] ? row[0].toString().replace(/(')|(")/, "") : row[0], //Handle csv render hex
              [dataIndexDefault.receiverWalletAddress]: row[1] ? row[1].toString().replace(/(')|(")/, "") : row[1],
              [dataIndexDefault.smartContract]: row[2],
              [dataIndexDefault.tokenId]: row[3],
            };
            newRows.push(data);
          }
        });
        if (newRows.length === 0) {
          // setErrorMessage("No data found in file!");
          toast("No data found in file!", {
            type: "error",
          });
          return false;
        } else {
          const rawGroupRequestId = resp.groupRequestId.replaceAll(",", "");
          localStorage.setItem("rowExcel", JSON.stringify(newRows));
          localStorage.setItem("groupRequestId", JSON.stringify(rawGroupRequestId));
          if (resp.groupRequestId && numberOfData > 1) {
            setGroupRequestId(rawGroupRequestId);
          }
          // setCols(resp.rows[numberOfData - 1]);
          setRows(newRows);
          // setErrorMessage(null);
        }
      }
    };

    let fileObj = fileList;
    if (!fileObj) {
      // setErrorMessage("No file uploaded!");
      return false;
    }
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileObj.type === "text/csv"
      )
    ) {
      // setErrorMessage("Unknown file format. Only Excel files are uploaded!");
      toast("Unknown file format. Only Excel files are uploaded!", {
        type: "error",
      });
      return true;
    }
    localStorage.setItem("nameOfExcel", JSON.stringify(fileObj.name));
    ExcelRenderer(fileObj, callBack, fileObj.type === "text/csv");
    return false;
  };
  //Function handle launch distribute
  const handleLaunchDistribute = async () => {
    if (!conditionActiveLaunchExcel) {
      toast(messageErrorSenderInvalidAccount, {
        type: "warning",
      });
      return;
    }
    if (groupRequestId && !validate(groupRequestId)) {
      toast(messageErrorGroupRequestId, {
        type: "warning",
      });
      return;
    }
    const transferredItems: ItemSignableTransferParams[] = [];
    rows.map((item, index) => {
      const itemsTransfer: ItemSignableTransferParams = {
        quantizedAmount: String(1),
        receiverWalletAddress: item.receiverWalletAddress,
        tokenType: TokenType.MINTABLE_ERC721,
        tokenData: {
          tokenAddress: item.smartContract,
          tokenId: item.tokenId,
        },
      };
      transferredItems.push(itemsTransfer);
    });

    const bulkTransferNFTsToken = async (transferTokenParams: TransferTokenParams) => {
      const moduleFactory = await getModuleFactory();
      const transactionManager = moduleFactory.getTransactionManager();
      console.log("Transfer token data -> ", transferTokenParams);
      try {
        const transferResult = await asyncCallWithTimeout(transactionManager.bulkTransferNfts(transferTokenParams), timeoutBulkTransfer);
        console.log("transferResult -> ", transferResult);
        if (transferResult.status === "success" && groupRequestId) {
          setActiveLoading(false);
          toast("Transfer Success", {
            type: "success",
          });
          navigate.push(`/admin-tools/retryDistribute/${encodeURIComponent(groupRequestId)}`);
        }
      } catch (error: any) {
        const errorMessage = error.message;
        const indexErrorObject = errorMessage.indexOf("{");
        setActiveLoading(false);
        if (indexErrorObject === -1) {
          toast(errorMessage.messageRequestTimeOut, {
            type: "error",
          });
        } else {
          const jsonError = parseErrorAxios(error);
          if (!jsonError.status) {
            toast(messageUserNoSignature, {
              type: "error",
            });
          } else if (jsonError?.errors[0]?.externalErrors?.errors[0]?.detail) {
            toast(jsonError?.errors[0]?.externalErrors?.errors[0]?.detail, {
              type: "error",
            });
          } else if (jsonError.errors[0].detail) {
            toast(jsonError.errors[0].detail, {
              type: "error",
            });
          } else {
            toast(jsonError.errors[0].detail[0], {
              type: "error",
            });
          }
        }
      }
    };
    if (transferredItems.length > maxTransactionData) {
      toast(messageErrorMaxTransaction, {
        type: "error",
      });
    } else {
      const transferTokenParams: TransferTokenParams = {
        senderWalletAddress: walletAddress,
        requestId: groupRequestId,
        groupRequestId: groupRequestId,
        partnerRefId: partnerRefId,
        description: nftDescription,
        items: transferredItems,
      };
      setActiveLoading(true);
      await bulkTransferNFTsToken(transferTokenParams);
    }
  };

  // // Function handle delete rows
  // const handleDelete = (key: any) => {
  //   const variableRows = [...rows];
  //   const newRows = variableRows.filter((item) => item.key !== key);
  //   setRows(newRows);
  // };
  // // Add row
  // const handleAdd = () => {
  //   const variableRows = rows;
  //   const newData: Item = {
  //     [dataIndexDefault.key]: rows.length,
  //     [dataIndexDefault.sender]: "",
  //     [dataIndexDefault.receiverWalletAddress]: "",
  //     [dataIndexDefault.token]: "",
  //   };
  //   setRows([newData, ...variableRows]);
  // };
  //Check column editable
  const column = columns.map((col, index) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ItemTable) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: isEditable ? handleSave : undefined,
      }),
    };
  });
  return (
    <div className="min-w-[992px]">
      <div className="flex justify-center items-center text-2xl font-semibold pt-4">
        <img className="w-6 h-6" src="/assets/images/santa-hat.png" />
        <h1 className="mx-4">Distribute AirDrop Dashboard</h1>
        <img className="w-6 h-6" src="/assets/images/santa-hat.png" />
      </div>
      <Row gutter={16} justify="space-between">
        <Col className="flex justify-between items-center mb-[5%]" span={8}></Col>
        <Col span={16} style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}>
          <CSVRequestsExport filename="distributeNFT.csv" cols={column} template={true} />
          <ExcelRequestsExport filename="distributeNFT.xlsx" cols={column} template={true} />
          {/* {rows.length > 0 ? <ExcelRequestsExport filename="requests.xlsx" cols={column} rows={rows} template={false} /> : null} */}
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col style={{ maxWidth: "260px" }}>
          <Upload
            name="file"
            beforeUpload={fileHandler}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onRemove={() => {
              localStorage.removeItem("rowExcel");
              localStorage.removeItem("nameOfExcel");
              localStorage.removeItem("groupRequestId");
              setRows([]);
              setActiveLaunch(false);
              setGroupRequestId("");
            }}
            multiple={false}
            maxCount={1}
            defaultFileList={
              nameOfExcel
                ? [
                    {
                      uid: "1",
                      name: nameOfExcel.replaceAll('"', ""),
                      status: "done",
                    },
                  ]
                : undefined
            }
          >
            <Button className="text-base flex items-center hover:text-header-button-hoverTextColor hover:bg-[#023d3d] text-white">
              <UploadOutlined /> Click to Upload Excel File
            </Button>
          </Upload>
        </Col>
        {groupRequestId && groupRequestId.length > 1 ? (
          <Col span={12} style={{ display: "flex", justifyContent: "center", paddingTop: "50px" }}>
            <h2 className="mr-3 text-base">Group Request ID:</h2>
            <span className="text-base">{groupRequestId}</span>
          </Col>
        ) : null}
        <Col span={5} style={{ display: "flex", justifyContent: "flex-end" }}>
          {rows.length > 0 && (
            <>
              {/* <Button onClick={handleAdd} size="large" style={{ marginBottom: 16, marginLeft: 10 }} className="text-white flex items-center">
                <PlusOutlined />
                Add a row
              </Button>{" "} */}
              {/* <Button onClick={handleSubmit} size="large" style={{ marginBottom: 16, marginLeft: 10 }} className="text-white">
                Save
              </Button> */}
              {groupRequestId && validate(groupRequestId) ? (
                <Button size="large" className="text-white mb-4 ml-3 disabled:text-current">
                  <Link to={`/admin-tools/retryDistribute/${groupRequestId}`}>Transaction History</Link>
                </Button>
              ) : null}
              <Button disabled={!activeLaunch} onClick={handleLaunchDistribute} size="large" className="text-white mb-4 ml-3 disabled:text-current">
                Launch
              </Button>
            </>
          )}
        </Col>
      </Row>
      <div className="mt-5 min-w-[1024px]">
        <TableComp
          rows={rows}
          column={column}
          pagination={{
            position: ["bottomRight", "topRight"],
            onChange: onChangePage,
            defaultPageSize: 100,
          }}
        />
      </div>
      {rows.length > 0 && activeLoading && !!groupRequestId ? <LoadingDistribute /> : null}
    </div>
  );
};

export default DistributesNFTComp;
