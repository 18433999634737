import React from "react";
import { CollectionListResponse } from "myria-core-sdk";
import { useHistory } from "react-router-dom";

interface PropTypes {
  collectionInfo: CollectionListResponse;
}

export default function Collection({ collectionInfo }: PropTypes) {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/collections/${collectionInfo.id}`);
  };

  return (
    <div
      className="card md:w-5/12 w-10/12 max-w-[616px] flex flex-col items-center cursor-pointer rounded-lg hover:shadow-slate-700 hover:shadow-md m-8"
      onClick={() => handleClick()}
    >
      <div className="w-full h-[248px]">
        <img
          src={collectionInfo.collectionImageUrl ? collectionInfo.collectionImageUrl : "/assets/images/placeholder.jpg"}
          className="object-cover object-center rounded-t-lg w-full h-full"
        ></img>
      </div>

      <div
        className="w-full h-[189px] bg-header-button-hover relative flex flex-col items-center rounded-b-lg"

      >
        <div
          style={{
            backgroundImage: `${collectionInfo.iconUrl ? 'url(' + collectionInfo.iconUrl + ')' : 'url(/assets/images/logo192.png)'}`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#0b2231'
          }}
          className="border-4 border-header-button-hover w-20 h-20 rounded-full absolute -translate-y-2/4 z-10"
        ></div>
        <div className="flex flex-col mt-10 items-center justify-around w-full h-32">
          <div className="text-white text-2xl font-bold">{"Collection Id: " + collectionInfo.id}</div>
          <div className="text-white text-2xl font-bold">{collectionInfo.project.name}</div>
          <div className="text-header-button-hoverTextColor text-xl font-semibold">by {collectionInfo.project.companyName}</div>
          <div className="text-header-button-hoverTextColor text-xl font-semibold">{collectionInfo.name}</div>
        </div>
      </div>
    </div>
  );
}
