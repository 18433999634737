import { Loading3QuartersOutlined } from "@ant-design/icons";
import React from "react";

interface Props {}

const LoadingDistribute: React.FC<Props> = () => {
  return (
    <div className="fixed inset-0 bg-black opacity-80 z-10">
      <div className="flex items-center justify-center m-auto w-full h-full z-10">
        <Loading3QuartersOutlined className="flex justify-center animate-spin text-5xl" style={{ height: "unset", paddingTop: "0" }} />
      </div>
    </div>
  );
};

export default LoadingDistribute;
